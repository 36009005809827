<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2d"
            defaultEnd="6d"
            label="작업계획기간"
            name="plantDts"
            v-model="searchParam.plantDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field type="search" label="요청부서/담당자" name="reqDeptCd" v-model="searchParam.reqDeptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field type="search" label="작업부서/담당자" name="deptCd" v-model="searchParam.deptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="comboItems"
            label="진행상황"
            v-model="searchParam.woWorkPlanStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="워크오더 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="워크오더 생성" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='col2'">
          <q-chip
            :color="getColors(props.row.col2)"
            outline square
            :class="props.row.col2 == '5' ? 'blinking' : ''"
            text-color="white">
            {{props.row.col2Name}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDts: [],
        plantDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkPlanStepCd: null,
        pmFlag: 'N',
      },
      popupOptions: {
        isFull: true,
        target: null,
        suffixChip: '',
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'col2',
            field: 'col2',
            label: '진행상황',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            fix: true,
            name: 'col4',
            field: 'col4',
            label: '작업내용',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'link'
          },
          {
            name: 'col3',
            field: 'col3',
            label: '작업분류',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '작업계획기간',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '요청부서/담당자',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '작업부서/담당자',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
      comboItems: [
        {
          code: '1',
          codeName: '작성중',
        },
        {
          code: '2',
          codeName: '계획수립',
        },
        {
          code: '3',
          codeName: '개선중',
        },
        {
          code: '4',
          codeName: '조치완료',
        },
        {
          code: '5',
          codeName: '계획수립기간 지남',
        },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantName: '사업장1',
          col2: '2',
          col2Name: '계획수립',
          col3: '환경설비',
          col4: '집진기 백필터 교체',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
        {
          plantName: '사업장1',
          col2: '2',
          col2Name: '계획수립',
          col3: '하역기',
          col4: '하역기 부식',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
        {
          plantName: '사업장1',
          col2: '1',
          col2Name: '작성중',
          col3: 'BC (01)',
          col4: 'BC 교체',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
        {
          plantName: '사업장1',
          col2: '3',
          col2Name: '개선중',
          col3: 'TT',
          col4: '집진기 백필터 교체',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
        {
          plantName: '사업장1',
          col2: '4',
          col2Name: '조치완료',
          col3: '창고 1~5구간',
          col4: '창고 1~5구간 확인',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
        {
          plantName: '사업장1',
          col2: '5',
          col2Name: '계획수립기간 지남',
          col3: '안전설비',
          col4: '안전설비 백필터 교체',
          col5: '요청부서/백세종',
          col6: '담당부서/방정현',
          col7: '2023-10-25 ~ 2023-10-27',
        },
      ]
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '워크오더 상세';
      this.popupOptions.suffixChip = row ? row.col2Name : '';
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case '1': // 작성중
          returnColor = 'grey-6';
          break;
        case '2': // 계획수립
          returnColor = 'blue';
          break;
        case '3': // 개선중
          returnColor = 'orange';
          break;
        case '4': // 조치완료
          returnColor = 'green';
          break;
        case '5': // 지연
          returnColor = 'red';
          break;
      }

      return returnColor;
    },
  }
};
</script>
